import React from "react"
import "../styles/layout.scss"
import ShockMarketOrg from "./../images/shock-market-lockup.png";
import UpArrow from "./../images/svg/up-arrow.inline.svg"

const TwitterCard = ({ pageContext }) => {
  const category = pageContext.category;
  const stat = pageContext.stat.stat_name
  const last_updated = pageContext.lastUpdated.split(' ')[0]


  const daybefore = () => {
    const date = new Date(last_updated);
    const yesterday = new Date(date.getTime());
    yesterday.setDate(date.getDate() - 1);
    const year = yesterday.getFullYear();
    return yesterday
      .toISOString()
      .split("T")[0]
      .split("-")
      .slice(1, 3)
      .join(".").concat(`.${year}`);
  }
 
  let category_title;
  switch(category) {
    case 'yesterday': 
      category_title = `on ${daybefore()}`
      break;
    case 'past_seven_days':
      category_title = 'in the Past Seven Days'
      break;
    case 'under_biden':
      category_title = "Since Biden’s Inauguration"
      break;
    default: category_title = 'yesterday'
  }

  let stat_name_title;
  switch (stat) {
    case 'deaths':
      stat_name_title = 'Gun Deaths';
      break;
    case 'injuries':
      stat_name_title = 'Gun Injuries';
      break;
    case 'suicides':
      stat_name_title = 'Suicides by Gun';
      break;
    case 'minors killed':
      stat_name_title = 'Minors Killed by Guns';
      break;
    case 'minors injured':
      stat_name_title = 'Minors Injured by Guns';
      break;
    case 'mass shootings':
      stat_name_title = 'Mass Shootings';
      break;
    case 'unintentional shootings':
      stat_name_title = 'Unintentional Shootings';
      break;
    case 'murder-suicide incidents':
      stat_name_title = 'Murder-Suicides by Guns';
      break;
    case 'law enforcement officers injured/killed':
      stat_name_title = 'Officers Injured or Killed by Guns';
      break;
    case 'law enforcement involved injuries/deaths':
      stat_name_title = 'Subject-suspects Injured or Killed by Guns';
      break;
    case 'accidental shootings':
      stat_name_title = 'Unintentional Shootings';
      break;
    default: stat_name_title = 'Gun Deaths'  
  }

  const statNameLength = stat_name_title.length;

  let size = '';
  if (statNameLength >= 36) {
    size = 'extra-extra-long'
  } else if (statNameLength >= 25) {
    size = 'extra-long'
  } else if (statNameLength >= 22) {
    size =  'long'
  } else if (statNameLength >= 15) {
    size = 'med'
  } else if (statNameLength >= 10) {
    size = 'small'
  }

  // const size = pageContext.stat.stat_name.length > 15 ? 'long' : '' ;

  return (
    <div className="tc gradient-bkd">
      <div className="tc__info-wrap">
        <h1 className={`tc__name tc__name--${size}`}>{stat_name_title}</h1>
        <h3 className="tc__category">{category_title}</h3>
        <h2 className="tc__number">
          {pageContext.category !== 'under_biden' && (
            <div className="tc__up-arrow">
              <UpArrow />
            </div>
          )}
          {pageContext.stat.stat_number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </h2>
        <p className="tc__updated">last updated {last_updated.replaceAll('/', '.')}</p>
      </div>
      <img className="tc__lockup" src={`${ShockMarketOrg}`} alt="check out shockmarket.org"/>
      <p className="tc__source">Data source: GunViolenceArchive.org</p>
    </div>
  )
}

export default TwitterCard;